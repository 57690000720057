import React from "react";

import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SimpleLanding from "../../../layouts/simple-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import CheckingOverview from "../../../components/checking-overview/checking-overview";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const MortgageOffer = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "closing-cost-hero",
    ...getHeroImgVariables(imgData),
    altText: "Young couple moving in new house, holding keys",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Save on Mortgage Costs When You Open a WaFd Bank Checking Account"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Mortgage Offer"
    }
  ];

  const SEOData = {
    title: "Save on Mortgage Closing Costs",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Save on Mortgage Closing Costs"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Find out how you can save up to $600 on closing costs on your mortgage with WaFd Bank's checking account offer for new and existing customers."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/mortgage-offer"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-closing-costs-03132023.jpg"
      }
    ]
  };

  const CheckingOverviewData = {
    asterisk1: "**",
    asterisk2: "**",
    mAsterisk1: "**",
    mAsterisk2: "**",
    hasFootnotes: false,
    hasMFootnotes: false,
    isSimpleHeader: true
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Enjoy a great start with WaFd Bank by saving up to $600 on your loan closing costs.*</h1>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-success">Follow these three simple steps:</h2>
            <ol className="text-lg" id="mortgage-offer-steps">
              <li id="mortgage-offer-steps-1">
                Choose your best fit checking account.{" "}
                <Link to="#checking-overview-section" id="see-below-link">
                  See below
                </Link>{" "}
                to review your options, including interest-earning, free and low minimum balance accounts.
              </li>
              <li id="mortgage-offer-steps-2">Provide the new account verification email to your Loan Officer.</li>
              <li id="mortgage-offer-steps-3">Complete the EZ-Pay sign up form with your Loan Officer.</li>
            </ol>
          </div>
          <div className="col-md-6">
            <div className="d-none d-md-block">
              <div className="row">
                <div className="col-md-6">
                  <div style={{ maxHeight: "293px", height: "300px" }}>
                    <StaticImage
                      src="../../../images/third-party/walt-from-packaging-pdf-touched-up.png"
                      style={{ maxHeight: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      alt="WaFd Walt Bank"
                    />
                  </div>
                </div>
                <div className="col-md-6" id="save-info">
                  <h3 className="text-success font-weight-bolder mb-0" id="save-info-400-title">
                    Save $400
                  </h3>
                  <p id="save-info-400-text">
                    when you set up autopay, pulling your mortgage payment from your checking account at WaFd or any
                    other bank.
                  </p>
                  <h3 className="text-success font-weight-bolder mb-0" id="save-info-200-title">
                    Save another $200
                  </h3>
                  <p id="save-info-200-text">
                    if you make your mortgage payments from a WaFd Bank checking account (with direct deposit).
                  </p>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none" id="save-info-mobile">
              <h3 className="text-success font-weight-bolder mb-0" id="save-info-mobile-400-title">
                Save $400
              </h3>
              <p id="save-info-mobile-400-text">
                when you set up autopay, pulling your mortgage payment from your checking account at WaFd or any other
                bank.
              </p>
              <h3 className="text-success font-weight-bolder mb-0" id="save-info-mobile-200-title">
                Save another $200
              </h3>
              <p id="save-info-mobile-200-text">
                if you make your mortgage payments from a WaFd Bank checking account (with direct deposit).
              </p>
            </div>
          </div>
        </div>
      </section>
      <CheckingOverview {...CheckingOverviewData} />
      <section className="container pt-0">
        <p className="text-muted" id="mortgage-offer-disclaimer-1">
          *To qualify for a credit back on closing costs, prior to closing, you must (1) open a new consumer checking
          account with WaFd Bank using the link above, (2) register for EZ-Pay service to automatically pull your
          mortgage payment from this checking account, and (3) include one form of direct deposit (payroll, social
          security, or other monthly recurring source of income). This credit may not be combined with other offers or
          discounts. Direct deposit must be verified. All loans subject to credit approval.
        </p>
        <p className="text-muted" id="mortgage-offer-disclaimer-2">
          **Requires eStatement registration and direct deposit. Nonsufficient/returned item fees may apply. $25 minimum
          to open. Inactivity Fee May Apply.
        </p>
      </section>
    </SimpleLanding>
  );
};

export default MortgageOffer;
